@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}


// ==================================================
//  Body
// ==================================================
body {
  font-family: 'Hina Mincho', YuMincho, "Yu Mincho", "Hiragino Mincho ProN", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.78;
  min-width: 1240px;
  color: color.$write-main;
  background-color: color.$bg-main;

  @include mixin.sp {
    font-size: 1.4rem;
    min-width: 375px;
  }
}


// ==================================================
//  Main
// ==================================================
main {
  margin-top: 123px;

  @include mixin.sp {
    margin-top: 74px;
  }
}


// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  width: 100%;
  min-width: 1240px;
  background-color: color.$bg-green;
  padding: 44px 0;
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;

  @include mixin.sp {
    min-width: 375px;
    height: 74px;
    padding: 0;
  }

  &_inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 840px;
    max-width: calc(100% - (269px * 2));
    margin-right: auto;
    margin-left: auto;

    @include mixin.sp {
      justify-content: space-between;
      min-width: 0;
      max-width: calc(100% - 40px);
      height: 100%;
      align-items: center;
    }
  }

  &_logo {
    display: block;
    width: 100%;
    max-width: 380px;
    margin: 0;

    @include mixin.pc {
      position: absolute;
      top: calc(50% - 2px);
      left: 26px;
      transform: translateY(-50%);
    }

    @include mixin.sp {
      max-width: 240px;
      transform: translateY(-2px);
    }

    &_inner {
      display: block;
      width: 100%;
      @include mixin.hoverOpacity60;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &_menuButton {
    display: none;

    @include mixin.sp {
      display: block;
      z-index: 99;
      position: relative;
      width: 40px;
      height: 40px;
      margin: 2px 0 0 0;
      overflow: hidden;
      background-image: url(../img/common/icon_menu_button.svg);
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      text-indent: 100%;
    }
  }

  &_menu {
    @include mixin.sp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 95;
      top: 0;
      left: 0;
      background-color: color.$bg-green;
      padding: 20px 0 30px 0;
    }

    // Javascript
    &.js-headerMenu {
      @include mixin.sp {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
      }

      // Modifier
      &-active {
        @include mixin.sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_list {
      display: flex;
      column-gap: 30px;
      list-style: none;
      margin: 0;
      padding: 0;

      @include mixin.sp {
        flex-direction: column;
        align-items: center;
        column-gap: 0;
        row-gap: 40px;
      }

      &_item {
        font-size: 1.7rem;
        line-height: 1.47;
        color: color.$write-yellow;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          font-size: 1.8rem;
          line-height: 1.55;
        }

        &::after {
          content: "";
          display: block;
          width: 38px;
          height: 6px;
          background-image: url(../img/common/icon_menu.svg);
          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;
          margin: 4px auto 0;

          @include mixin.sp {
            margin-top: 6px;
          }
        }

        &_inner {
          text-decoration: none;
        }
      }
    }

    &_bannerReserve {
      display: block;
      width: 100%;
      max-width: 150px;
      @include mixin.hoverOpacity60;

      @include mixin.pc {
        position: absolute;
        top: 44px;
        right: 30px;
      }

      @include mixin.sp {
        max-width: 140px;
        margin-top: 55px;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }
  }
}


// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  position: fixed;
  z-index: 90;
  right: 25px;
  bottom: 40px;

  @include mixin.sp {
    right: 18px;
    bottom: 18px;
  }

  &_copyright {
    margin: 0 auto 27px;
    font-size: 1.4rem;
    line-height: 1;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    @include mixin.sp {
      margin-bottom: 20px;
      font-size: 1.3rem;
    }
  }

  &_instagram {
    display: block;
    width: 34px;
    height: 34px;
    background-image: url(../img/common/icon_instagram.png);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: 100%;
    overflow: hidden;
    text-decoration: none;
    @include mixin.hoverOpacity60;
  }
}


// ==================================================
//  Reserve Link
// ==================================================
.reserveLink {
  display: block;
  width: 100%;
  max-width: 650px;
  font-size: 3.7rem;
  line-height: 1.2;
  text-align: center;
  margin: 130px auto 0;
  text-decoration: none;
  color: color.$write-white;
  background-color: color.$bg-reserve-link;
  border: 5px solid color.$border-reserve-link;
  border-radius: 60px;
  padding: 20px 0 10px;
  @include mixin.hoverOpacity60;

  @include mixin.sp {
    max-width: 325px;
    font-size: 2.1rem;
    margin-top: 40px;
    border-radius: 40px;
    border-width: 3px;
    padding: 18px 0 10px 4px;
  }

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 58px;
    height: 52px;
    background-image: url(../img/common/icon_hand.png);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-5px);
    margin-right: 32px;

    @include mixin.sp {
      width: 36px;
      height: 33px;
      transform: translateY(-3px);
      margin-right: 18px;
    }
  }
}